import React from "react"
import Layout from "../../../components/sermonLayoutSurprise"
import SEO from "../../../components/seo"

const SermonPost = () => (
  <Layout sermonSrc="https://www.youtube.com/embed/bAbpaKYeZ0I">
    <SEO title="A Guide to Visions and Dreams - Living in Babylon" />
  </Layout>
)

export default SermonPost
